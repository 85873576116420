<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title>All Orders Report</v-card-title>
          <v-card-text>
            Export an orders report to CSV
          </v-card-text>
          <v-card-actions>
            <download-csv
              :data="morphDatesToPresentable(allOrders)"
              name="orders.csv"
            >
              Download
            </download-csv>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title>Print Licenses</v-card-title>
          <v-card-text>
            Export License Prints
          </v-card-text>
          <v-card-actions>
            <download-csv
              :data="morphDatesToPresentable(allOrders)"
              name="orders.csv"
            >
              Download
            </download-csv>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title>Headshot Export</v-card-title>
          <v-card-text>
            Export Headshots
          </v-card-text>
          <v-card-actions>
            <download-csv
              :data="morphDatesToPresentable(allOrders)"
              name="orders.csv"
            >
              Download
            </download-csv>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import JsonCSV from 'vue-json-csv'
  import moment from 'moment'

  export default {
    components: { downloadCsv: JsonCSV },
    computed: {
      ...mapGetters(['allOrders']),
    },
    methods: {
      ...mapActions(['getAllOrders']),
      morphDatesToPresentable (orderSet) {
        return orderSet.map(order => {
          const newObj = {}

          Object.keys(order).forEach((key) => {
            newObj[key] = JSON.stringify(order[key])
          })

          newObj.timestamp_created = moment(newObj.timestamp_created, 'X').format('LLL')
          return newObj
        })
      },
    },
  }
</script>
